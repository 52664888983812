import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/page-title"
import { pageContent404 } from "../markdown-text/content"
import { useStateValue } from "../contexts/state-context-provider"

const historyBack = () => {
  return window.history.go(-1)
}

const NotFoundPage = () => {
  const { language } = useStateValue()
  return (
    <Layout>
      <SEO title="404: Not found" />
      <PageTitle
        style={{ fontSize: "2rem", marginBottom: "4rem" }}
        hTitle={pageContent404[language].h}
      />
      <section>
        <p>{pageContent404[language].p}</p>
        <button
          style={{
            fontFamily: "'Exi-Medium', sans-serif",
            textDecoration: "underline",
            color: "#1501FF",
            cursor: "pointer",
            fontSize: "1rem",
          }}
          onClick={historyBack}
        >
          {pageContent404[language].button}
        </button>
      </section>
    </Layout>
  )
}

export default NotFoundPage
